import request from './request'

export const getDevicesList = (data) => {
  return request.post('/jf-tech/v1/getDevices', data)
}

// export const editGoods = (data) => {
//   return request.post('/jf-tech/v1/updateGoods', data)
// }

// export const addGoods = (data) => {
//   return request.post('/jf-tech/v1/addGoods', data)
// }

export const unbindDevice = (data) => {
  return request.post('/jf-tech/v1/unbindDevice', data)
}

// export const get3DBallData = (data) => {
//   return request.get('/get3DBallData', data)
// }

// export const delImgItem = (data) => {
//   return request.post('/jf-tech/v1/deleteGoodsImages', data)
// }

export const getDeviceHistoryData = (data) => {
  return request.post('/v1/devices/getDeviceHistoryData', data)
}

export const getDeviceLatestData = (data) => {
  return request.post('/v1/devices/getDeviceLatestData', data)
}

export const getHoursDataByDatetime = (data) => {
  return request.post('/v1/devices/getHoursDataByDatetime', data)
}

export const requestDownloadLog = (name) => {
  return request.get('/jf-tech/v1/deviceLogDown?name=' + name)
}
